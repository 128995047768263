import React from 'react'
// import { Link } from "gatsby"
import homeServices from '../css_modules/homeservices.module.scss'
import globalStyle from '../css_modules/global.module.scss'
import contactForm from '../css_modules/contactform.module.scss'

const ContactFormSection = () => {
    return (
        <section className={homeServices.homeServicesSection}>
            <div className={homeServices.sectionLeft}>
                <div className={globalStyle.container}>
                    <h2 className={globalStyle.heroTitle}>We'd love to hear from you!<br /><label> contact us</label></h2>
                </div>
                <div className={globalStyle.grayBackground + ' ' + contactForm.grayBackground}>
                    <div className={globalStyle.container}>
                        <div className={contactForm.formSection}>
                            <form name="contact" netlify netlify-honeypot="bot-field" hidden>
                                <input type="text" name="name" placeholder="" />
                                <input type="text" name="phone" />
                                <input type="email" name="email" />
                                <input type="text" name="company" />
                                <input type="text" name="website" />
                                <textarea name="message"></textarea>
                            </form>
                            <form name="contact" method="POST" data-netlify="true">
                                <input type="hidden" name="form-name" value="contact" />
                                <p>

                                    <label className={globalStyle.pureMaterialTextfieldOutlined}>
                                        <input type="text" name="name" placeholder="" />
                                        <span>Name and Surname</span>
                                    </label>
                                </p>
                                <p>
                                    <label className={globalStyle.pureMaterialTextfieldOutlined}>
                                        <input type="text" name="phone" />
                                        <span>Phone number</span>
                                    </label>
                                </p>
                                <p>
                                    <label className={globalStyle.pureMaterialTextfieldOutlined}><input type="email" name="email" />
                                        <span>Your email</span>
                                    </label>
                                </p>
                                <p>
                                    <label className={globalStyle.pureMaterialTextfieldOutlined}>
                                        <input type="text" name="company" />
                                        <span>Company name</span>
                                    </label>
                                </p>
                                <p>
                                    <label className={globalStyle.pureMaterialTextfieldOutlined}>
                                        <input type="text" name="website" />
                                        <span>Website URL</span>
                                    </label>
                                </p>
                                <p>
                                    <label className={globalStyle.pureMaterialTextfieldOutlined}>
                                        <textarea name="message"></textarea>
                                        <span>Details about your business</span>
                                    </label>
                                </p>
                                <button type="submit" className={globalStyle.mainButton}>Send</button>
                            </form>
                        </div>
                    </div>
                    <div className={homeServices.sectionRight}>
                        <div className={homeServices.imgHolder + ' ' + contactForm.imgHolder}></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactFormSection