import React from 'react'
import homeHero from '../css_modules/homehero.module.scss'
import globalStyle from '../css_modules/global.module.scss'
import contactHero from '../css_modules/contacthero.module.scss'
import { Link } from "gatsby"

const ContactHeroSection = () => {
    return (
        <section className={homeHero.heroSectionHome + ' ' + contactHero.heroSectionContact}>
            <div className={globalStyle.container}>
                <h1 className={globalStyle.h1Hero}>Contact us to help you<br /><label>grow your digital business</label></h1>
            </div>
            <div className={homeHero.heroSectionContent}>
                <div className={homeHero.imgPlaceholder + ' ' + contactHero.imgPlaceholder}>

                </div>
                <div className={homeHero.contentRight}>
                    <p>We offer support in strategies and concepts to help your business thrive. We customise our lead marketing methodologies to suit your needs. Get in touch to find out how we can help your business prepare for the future.</p>
                    <Link to="our-services" className={globalStyle.mainButton}>Find out how</Link>
                </div>
            </div>
        </section >
    )
}

export default ContactHeroSection