import React from 'react';
import Layout from '../components/layout'
import SEO from '../components/seo'
import HomeNewsletterSection from '../components/homepage/homenewsletter'
import ContactHeroSection from '../components/contact/contacthero'
import ContactFormSection from '../components/contact/contactform';

const ContactPage = () => {
    return (
        <Layout>
            <SEO title="Contact | Digital Community building &amp; nurturing - Digital Madness<" />
            <ContactHeroSection />
            <ContactFormSection />
            <HomeNewsletterSection />
        </Layout>
    )
}

export default ContactPage